import React, { useState } from 'react';
import OpenAI from "openai";
import Loading from '../component/loading';
import Alert from '../component/alert';

const API_KEY = process.env.REACT_APP_OPENAI_API_KEY;

const openai = new OpenAI({apiKey: API_KEY, dangerouslyAllowBrowser: true});


const ImageGenerator = () => {

    const [prompt, setPrompt] = useState('');
    const [images, setImages] = useState([]);
    const [loading, isLoading] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    

    const alertMessage = "Warning!! Your prompt may contain text that is not allowed by our safety system."
    
      
    const generateImages = async () => {

        isLoading(true);
        setShowAlert(false);
        try {
            const response = await openai.images.generate({
            model: "dall-e-3",
            prompt: prompt,
            size: "1024x1024",
            })

            setImages(response.data);

        } catch (error) {
          if (error.response) {
            console.log(error.response.status);
            console.log(error.response.data);
          } else {
              console.log('Error:', error.message);
              setShowAlert(true);
          }
        } finally {
            isLoading(false);
        }
    };

    return (
        
        <main className="flex flex-col items-center max-w-fit max-h-fit mx-auto my-8 p-4 md:border-2 rounded-lg" >
        <p className='mx-auto p-2 font-mono font-semibold text-xl'>Image generator</p>
        <div className='flex flex-col items-center mx-auto px-2 '>
        <input
            type="text"
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
            placeholder="Enter a prompt"
            className="w-full md:w-96 border-2 font-mono rounded-lg p-2 mb-4 hover:border-blue-500"
          />
          <button onClick={generateImages} className="bg-blue-500 hover:bg-blue-700 h-11  text-white mx-2 px-4 py-2 rounded">
            Generate Images
          </button>
        </div>
        {loading ? <Loading/> :  
        <div className="max-w-96 m-2 md:w-auto">
          {images.map((image, index) => (
            <img key={index} src={image.url} alt={''} className="max-w-full mb-4 rounded-lg" />
          ))}
          
        </div>}
        {showAlert && <Alert message={alertMessage} />}
        <div>
          <p>&copy; {new Date().getFullYear()} Armand. All Rights Reserved.</p>
        </div>
      </main>
      
    );
}

export default ImageGenerator;