import { useState } from 'react'

const Alert = ({ message }) => {
    const [isVisible, setIsVisible] = useState(true);

    if (!isVisible) {
        return null;
    }
    return (
        <div role="alert" className="flex items-center justify-between p-4 border-l-4 rounded shadow bg-yellow-100 border-yellow-400 text-yellow-700" >
            <span className="flex-1">{message}</span>
            <button
                onClick={() => setIsVisible(false)}
                className="ml-4 text-sm font-bold text-current hover:underline"
            >
                Dismiss
            </button>
        </div>
    )

}
export default Alert;