import { Outlet } from "react-router";
// import SideBar from "../component/sideBar";


const RootLayout = () => {
    // const links = [
    //     { title: 'Img generator', url: '/' },
    //     { title: 'Chatbot', url: '/chat' },
    // ]
    return (
        <div className='flex min-h-screen w-screen justify-normal bg-gradient-to-r from-cyan-300 via-sky-100 to-blue-300'>
            {/* <SideBar links={links} /> */}
            <div className="flex flex-col w-full max-h-screen mx-6 py-4 md:max-w-auto">
                <main>
                    <Outlet />
                </main>
            </div>   
            
        </div>
    );
}

export default RootLayout;