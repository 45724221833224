import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router';
import './index.css';
import Chat from './pages/chat';
import ImageGenerator from './pages/igen';
import RootLayout from './layout/root-layout';


const router = createBrowserRouter ([
  {
    element: <RootLayout />,
    children: [
      {path: '/', element: <ImageGenerator />},
      {path: '/chat', element: <Chat />}
    ]
  } 
])

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
